import { useEffect, useMemo } from 'react';
import { EditorProps, FieldLabelLengthLimit, PlaceholderTarget } from '../../form-builder/FormBuilderTypes';
import Checkbox, { SliderSize } from '../../shared/form-control/Checkbox';
import DropdownSelect from '../../shared/form-control/DropdownSelect';
import { Option } from '../../Option';
import { useTranslation } from 'react-i18next';
import { FormType } from '../../../models/FormTypes';
import PlaceholderTextBox from '../../shared/placeholder/PlaceholderTextBox';
import StringUtils from '../../../utils/StringUtils';
import Accordion from '../../shared/accordion/Accordion';
import { FCWithChildren } from '../../../types/FCWithChildren';
import TranslatableInput from '../../shared/form-control/TranslatableInput';
import ActionTypes from '../ActionTypes';
import LanguageUtils from '../../../utils/LanguageUtils';

type ActionEditorBaseProps = {
  enableTitleStyle?: boolean;
  questionKey?: string; // older action types use 'title' as key for the question
  altTitle?: string;
  altDescription?: string;
} & EditorProps;

// Keep the id's as is when making changes to styles
type InArr<T> = T extends readonly Option<string, string>[] ? (Option<string, string> & { text: T[number]['text'] })[] : never[];
const typeHelper = <T extends readonly Option<string, string>[]>(value: T) => value as unknown as InArr<T>;

export const titleStyleOptions = typeHelper([
  {
    id: 'heading-1',
    text: 'title-styles.h1',
    value: 'text-dpm-32 font-medium',
  },
  {
    id: 'heading-2',
    text: 'title-styles.h2',
    value: 'text-dpm-25 font-medium',
  },
  {
    id: 'heading-3',
    text: 'title-styles.h3',
    value: 'text-dpm-20 font-medium',
  },
  {
    id: 'heading-4',
    text: 'title-styles.normal',
    value: 'text-dpm-16 font-medium',
  },
] as const);

export const defaultTitleStyle = titleStyleOptions[3];

export const useTextStyle = (titleStyleId: string) => {
  return useMemo(() => titleStyleOptions.find((x) => x.id === titleStyleId) ?? defaultTitleStyle, [titleStyleId]);
};

const ActionEditorBase: FCWithChildren<ActionEditorBaseProps> = (props) => {
  const {
    action,
    questionKey = 'question',
    patchData,
    enableTitleStyle,
    form,
    patchForm,
    patchTranslations,
    referencedForms,
    children,
    altTitle,
    altDescription,
  } = props;
  const { translations } = action;
  const { requiresDescription, requiresHelp, titleStyle = defaultTitleStyle } = action?.data || {};
  const selectedTitleStyle = useTextStyle(titleStyle.id);

  const { t, i18n } = useTranslation('form-builder');
  const suggestedFieldLabel = useMemo(
    () => StringUtils.toLowerDashed(ActionTypes[action.type].actionTitle(action, i18n.language) || '', FieldLabelLengthLimit),
    [action, i18n.language],
  );

  useEffect(() => {
    if (!!LanguageUtils.getTranslation('description', translations || {}) && (requiresDescription === undefined || requiresDescription === null)) {
      patchData({ requiresDescription: true });
    }
  }, [patchData, requiresDescription, translations]);

  useEffect(() => {
    if (!!LanguageUtils.getTranslation('help', translations || {}) && (requiresHelp === undefined || requiresHelp === null)) {
      patchData({ requiresHelp: true });
    }
  }, [patchData, requiresHelp, translations]);

  return (
    <Accordion
      active={true}
      title={
        <div className="flex w-full flex-col pr-2">
          <div className="font-medium" data-cy="action-title">
            {t('action-properties.common.properties', { type: t(`action-types.${action.type}`) })}
          </div>
          <div onClick={(e) => e.stopPropagation()}>
            <TranslatableInput
              inputType="inline"
              fallbackValue={suggestedFieldLabel}
              placeholder={t('action-properties.common.field-label-placeholder')}
              size="text-dpm-16"
              maxLength={100}
              className="text-accent-1"
              translationKey="fieldLabel"
              translations={translations ?? {}}
              onTranslationsChange={patchTranslations}
            />
          </div>
        </div>
      }
    >
      <PlaceholderTextBox
        form={form}
        referencedForms={referencedForms}
        action={{ ...action, targetId: action.id, target: PlaceholderTarget.Action }}
        singleLine
        data-cy="question"
        inputPlaceholder={
          altTitle || (form.type === FormType.Resource ? t('action-properties.common.question-resource') : t('action-properties.common.question'))
        }
        label={
          altTitle || (form.type === FormType.Resource ? t('action-properties.common.question-resource') : t('action-properties.common.question'))
        }
        onPlaceholdersChange={(placeholders) => {
          patchForm({ placeholders });
        }}
        autoFocus
        enableLanguageToggle
        enableRichText={false}
        translationKey={questionKey}
        translations={translations ?? {}}
        onTranslationsChange={patchTranslations}
      />
      {enableTitleStyle && (
        <div className="flex items-center justify-between">
          <div>{t('action-properties.common.text-style')}</div>
          <div>
            <DropdownSelect
              options={titleStyleOptions.map((x) => ({ ...x, text: t(x.text) }))}
              value={{ id: selectedTitleStyle.id, text: t(selectedTitleStyle.text), value: selectedTitleStyle.value }}
              onChange={(option) => patchData({ titleStyle: option })}
              menuPosition="right"
            />
          </div>
        </div>
      )}
      <div className="mt-2 flex items-center justify-between">
        <div className={`${requiresDescription ? 'text-dpm-12 mt-4' : ''}`}>{altDescription || t('action-properties.common.description')}</div>
        <div>
          <Checkbox
            value={requiresDescription ?? false}
            onChange={(value) => patchData({ requiresDescription: value })}
            slider
            sliderSize={SliderSize.S}
          />
        </div>
      </div>
      {requiresDescription && (
        <>
          <PlaceholderTextBox
            form={form}
            referencedForms={referencedForms}
            action={{ ...action, targetId: action.id, target: PlaceholderTarget.Action }}
            data-cy="description"
            inputPlaceholder={altDescription || t('action-properties.common.description')}
            onPlaceholdersChange={(placeholders) => {
              patchForm({ placeholders });
            }}
            enableRichText
            enableLanguageToggle
            translationKey="description"
            translations={translations ?? {}}
            onTranslationsChange={patchTranslations}
          />
        </>
      )}
      <div className="mt-2 flex items-center justify-between">
        <div className={`${requiresHelp ? 'text-dpm-12 mt-4' : ''}`}>{t('action-properties.common.requires-help')}</div>
        <div>
          <Checkbox value={requiresHelp ?? false} onChange={(value) => patchData({ requiresHelp: value })} slider sliderSize={SliderSize.S} />
        </div>
      </div>

      {requiresHelp && (
        <>
          <PlaceholderTextBox
            form={form}
            referencedForms={referencedForms}
            action={{ ...action, targetId: action.id, target: PlaceholderTarget.Action }}
            data-cy="help"
            inputPlaceholder={t('action-properties.common.help-text')}
            onPlaceholdersChange={(placeholders) => {
              patchForm({ placeholders });
            }}
            enableRichText
            enableLanguageToggle
            translationKey="help"
            translations={translations ?? {}}
            onTranslationsChange={patchTranslations}
          />
        </>
      )}
      {children}
    </Accordion>
  );
};

export default ActionEditorBase;
